import omit from 'lodash/omit';
import React, { CSSProperties, FC, memo, useMemo } from 'react';
import Img, { I_Image } from '../image/img';

export interface I_Icon_image extends Omit<I_Image, 'width' | 'height'> {
  width?: string | number;
  height?: string | number;
}

const Icon_image: FC<I_Icon_image> = memo<I_Icon_image>((props) => {
  const { alt, width, height } = props;
  const aspect = useMemo(() => (width ? `${width}/${height || width}` : 1), [height, width]);
  const style = useMemo<CSSProperties>(
    () => ({
      width: width ?? '1.5rem',
      height,
      aspectRatio: aspect.toString(),
    }),
    [aspect, height, width],
  );
  return (
    <Img
      className="inline-block rounded-sm"
      style={style}
      data-testid="Icon_image"
      {...omit(props, ['width', 'height'])}
      alt={alt || `Icon image`}
    />
  );
});

export default Icon_image;
