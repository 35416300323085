import Icon_loading from '@/component/icon/icon_loading/icon_loading';
import Tip_text from '@/component/tip/tip_text/tip_text';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { useTranslation } from 'next-i18next';
import React, { ComponentProps, FC, memo, ReactNode, Suspense, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import Btn_action from '../../button/btn_action/btn_action';

export interface I_Modal_general extends Omit<ComponentProps<typeof Modal>, 'children'> {
  open: boolean;
  ops?: ReactNode;
  sx?: SxProps<Theme>;
  className?: string;
  testid?: string;
  children?: ReactNode;
}

export const style_modal_common: SxProps<Theme> = {
  maxWidth: '100%',
  width: 500,
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '10px',
  filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));',
};

const Modal_general: FC<I_Modal_general> = memo<I_Modal_general>((props) => {
  const { ops, children, onClose, sx, className, testid, ...rest } = props;
  const { t } = useTranslation();

  const _sx = useMemo<SxProps<Theme>>(() => {
    return {
      ...style_modal_common,
      p: '.5rem',
      ...sx,
    } as SxProps<Theme>;
  }, [sx]);

  return (
    <Modal {...rest} onClose={onClose}>
      <Suspense fallback={<Icon_loading />}>
        <Box
          className={twMerge('max-h-[calc(100vh-50px)] overflow-auto bg-background focus:outline-0', className)}
          sx={_sx}
          data-testid={testid}
        >
          {children}
        </Box>
        <div className="fixed bottom-1 right-1 top-auto z-[1400] inline-flex md:bottom-auto md:top-1">
          {ops && ops}
          <Tip_text title={t('common:close')}>
            <Btn_action onClick={onClose as any} data-testid="btn_modal_close">
              <CloseIcon className="text_dim" />
            </Btn_action>
          </Tip_text>
        </div>
      </Suspense>
    </Modal>
  );
});

export default Modal_general;
