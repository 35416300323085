import Icon_badge from '@/component/icon/icon_badge/icon_badge';
import { User } from '@/declaration/rds/model';
import { N_role } from '@/declaration/rds/model/type/n_role';
import {
  ChartPieIcon,
  ChatBubbleBottomCenterTextIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  Cog6ToothIcon,
  HeartIcon,
  HomeIcon,
  Squares2X2Icon,
  UserIcon,
} from '@heroicons/react/24/solid';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SavingsIcon from '@mui/icons-material/Savings';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import intersection from 'lodash/intersection';
import { ReactNode } from 'react';

export interface T_nav_item {
  name: string[]; // dict path (i18n)
  path?: string;
  icon?: ReactNode;
  children?: T_nav_item[];
  hidden?: (i: number, su?: User) => boolean;
  roles?: N_role[]; // only these roles should see the route
}

export const navs_dash: T_nav_item = {
  name: [],
  children: [
    {
      name: ['router', 'group', 'common'],
      roles: [N_role.employee],
      children: [
        {
          icon: <ViewTimelineIcon fontSize="small" />,
          name: ['dashboard', 'overview'],
          path: '/dashboard/overview',
        },
      ],
    },
    {
      name: ['router', 'group', 'rds'],
      roles: [N_role.super],
      children: [
        {
          name: ['dashboard', 'state'],
          path: '/dashboard/state',
        },
        {
          name: ['dashboard', 'redirector'],
          path: '/dashboard/redirector',
        },
        {
          name: ['dashboard', 'grave'],
          path: '/dashboard/grave',
        },
      ],
    },
    {
      name: ['router', 'group', 'task'],
      children: [
        {
          name: ['dashboard', 'task'],
          path: '/dashboard/task',
        },
      ],
    },
    {
      name: ['router', 'group', 'chain'],
      children: [
        {
          name: ['dashboard', 'block'],
          path: '/dashboard/block',
        },
      ],
    },
    {
      name: ['router', 'group', 'payment'],
      children: [
        {
          name: ['dashboard', 'order'],
          path: '/dashboard/order',
        },
      ],
    },
    {
      name: ['router', 'group', 'sender'],
      roles: [N_role.super],
      children: [
        {
          name: ['dashboard', 'email'],
          path: '/dashboard/email',
        },
        {
          name: ['dashboard', 'sms'],
          path: '/dashboard/sms',
        },
        {
          name: ['dashboard', 'review'],
          path: '/dashboard/review',
        },
        {
          name: ['dashboard', 'lease_rating'],
          path: '/dashboard/lease_rating',
        },
        // @@insert_nav_item
      ],
    },
    {
      name: ['router', 'group', 'antibot'],
      roles: [N_role.super],
      children: [
        {
          name: ['dashboard', 'vcode'],
          path: '/dashboard/vcode',
        },
        {
          name: ['dashboard', 'antibot'],
          path: '/dashboard/antibot',
        },
      ],
    },
    {
      name: ['router', 'group', 'log'],
      roles: [N_role.super],
      children: [
        {
          name: ['dashboard', 'state'],
          path: '/dashboard/log',
        },
      ],
    },
    {
      name: ['router', 'group', 'link'],
      roles: [N_role.super],
      children: [
        {
          name: ['dashboard', '$link', 'event_log'],
          path: '/dashboard/link_event_log',
        },
        {
          name: ['dashboard', '$link', 'job'],
          path: '/dashboard/link_job',
        },
        {
          name: ['dashboard', '$link', 'listener'],
          path: '/dashboard/link_listener',
        },
        {
          name: ['dashboard', '$link', 'listener_sub'],
          path: '/dashboard/link_listener_sub',
        },
        {
          name: ['dashboard', '$link', 'trigger'],
          path: '/dashboard/link_trigger',
        },
        {
          name: ['dashboard', '$link', 'trigger_sub'],
          path: '/dashboard/link_trigger_sub',
        },
      ],
    },
    {
      name: ['router', 'group', 'auth'],
      roles: [N_role.account_manager],
      children: [
        {
          name: ['dashboard', 'user'],
          path: '/dashboard/user',
        },
        {
          name: ['dashboard', 'account'],
          path: '/dashboard/account',
        },
        {
          name: ['dashboard', 'reserved_account_unique'],
          path: '/dashboard/reserved_account_unique',
        },
      ],
    },
    {
      name: ['router', 'group', 'resource'],
      roles: [N_role.super],
      children: [
        {
          name: ['dashboard', 'resource_job'],
          path: '/dashboard/resource_job',
        },
      ],
    },
    {
      name: ['router', 'group', 'finance'],
      roles: [N_role.finance_manger],
      children: [
        {
          name: ['dashboard', 'money'],
          path: '/dashboard/money',
        },
        {
          name: ['dashboard', 'money_log'],
          path: '/dashboard/money_log',
        },
        {
          name: ['dashboard', 'large_recharge'],
          path: '/dashboard/large_recharge',
        },
      ],
    },
    {
      name: ['router', 'group', 'product'],
      roles: [N_role.finance_manger],
      children: [
        {
          name: ['dashboard', 'product'],
          path: '/dashboard/product',
        },
      ],
    },
    {
      name: ['router', 'group', 'notification'],
      roles: [N_role.super],
      children: [
        {
          name: ['dashboard', 'nf'],
          path: '/dashboard/nf',
        },
      ],
    },
    {
      name: ['dashboard', 'group', 'storage'],
      children: [
        {
          name: ['dashboard', 'file'],
          path: '/dashboard/file',
        },
        {
          name: ['dashboard', 'comment'],
          path: '/dashboard/comment',
        },
        {
          name: ['dashboard', 'rating'],
          path: '/dashboard/rating',
        },
      ],
    },
    {
      name: ['router', 'group', 'passport'],
      roles: [N_role.account_manager],
      children: [
        {
          name: ['dashboard', 'passport'],
          path: '/dashboard/passport',
        },
      ],
    },
    {
      name: ['dashboard', 'group', 'wiki'],
      children: [
        {
          name: ['dashboard', 'segment'],
          path: '/dashboard/segment',
        },
      ],
    },
    {
      name: ['dashboard', 'group', 'feedback'],
      roles: [N_role.feedback_manager],
      children: [
        {
          name: ['dashboard', 'feedback_cat'],
          path: '/dashboard/feedback_cat',
        },
        {
          name: ['dashboard', 'feedback'],
          path: '/dashboard/feedback',
        },
        {
          name: ['dashboard', 'geo_node'],
          path: '/dashboard/geo_node',
        },
        {
          name: ['dashboard', 'geo_address'],
          path: '/dashboard/geo_address',
        },
        {
          name: ['dashboard', 'geo_node'],
          path: '/dashboard/geo_node',
        },
        {
          name: ['dashboard', 'geo_address'],
          path: '/dashboard/geo_address',
        },
      ],
    },
    {
      name: ['dashboard', 'group', 'im'],
      children: [
        {
          name: ['dashboard', 'chat'],
          path: '/dashboard/chat',
        },
        {
          name: ['dashboard', 'message'],
          path: '/dashboard/message',
        },
        {
          name: ['dashboard', 'topic_message'],
          path: '/dashboard/topic_message',
        },
      ],
    },
    {
      name: ['dashboard', 'group', 'app'],
      roles: [N_role.reviewer_manager],
      children: [
        {
          name: ['dashboard', 're'],
          path: '/dashboard/re',
        },
        {
          name: ['dashboard', 're_ai_support_simple_log'],
          path: '/dashboard/re_ai_support_simple_log',
        },
        {
          name: ['dashboard', 're_f_appliance'],
          path: '/dashboard/re_f_appliance',
        },
        {
          name: ['dashboard', 're_f_furniture'],
          path: '/dashboard/re_f_furniture',
        },
        {
          name: ['dashboard', 're_task'],
          path: '/dashboard/re_task',
        },
        {
          name: ['dashboard', 're_appointment'],
          path: '/dashboard/re_appointment',
        },
        {
          name: ['dashboard', 'reviewer'],
          path: '/dashboard/reviewer',
        },
        // @@insert_nav_item
      ],
    },
  ],
};

export const navs_signer: T_nav_item = {
  name: ['dashboard', 'my'],
  children: [
    // {
    //   icon: <ViewTimelineIcon fontSize="small" />,
    //   name: ['dashboard', '$my', 'overview'],
    //   path: '/dashboard/my/overview',
    // },
    {
      icon: <AssignmentIcon fontSize="small" />,
      name: ['dashboard', '$review', 'pending'],
      path: '/review/pending',
      hidden: (i: number, su?: User) => !!su?.id_reviewer,
    },
    {
      icon: <HomeIcon className="h-5" />,
      name: ['dashboard', '$my', 're'],
      path: '/dashboard/my/re',
    },
    {
      icon: <HeartIcon className="h-5" />,
      name: ['dashboard', '$my', 'saved'],
      path: '/dashboard/my/saved',
    },
    {
      icon: <ClockIcon className="h-5" />,
      name: ['dashboard', '$my', 're_appointment'],
      path: '/dashboard/my/re_appointment',
    },
    {
      icon: <ChartPieIcon className="h-5" />,
      name: ['dashboard', '$my', 'overview'],
      path: '/dashboard/my/overview',
    },
    {
      icon: <ClipboardDocumentListIcon className="h-5" />,
      name: ['dashboard', '$my', 'order'],
      path: '/dashboard/my/order',
    },
    {
      icon: <SavingsIcon fontSize="small" />,
      name: ['dashboard', '$my', 'asset'],
      path: '/dashboard/my/asset',
    },
    {
      icon: <Icon_badge className="size-[23px]" />,
      name: ['dashboard', '$my', 'role'],
      path: '/dashboard/my/role',
    },
    {
      icon: <ChatBubbleBottomCenterTextIcon className="h-5" />,
      name: ['dashboard', '$my', 'feedback'],
      path: '/dashboard/my/feedback',
    },
    {
      icon: <UserIcon className="h-5" />,
      name: ['dashboard', '$my', 'account'],
      path: '/dashboard/my/account',
    },
    // {
    //   icon: <WidgetsIcon fontSize="small" />,
    //   name: ['dashboard', '$my', 'app'],
    //   path: '/dashboard/my/app',
    // },
    // {
    //   icon: <SettingsIcon fontSize="small" />,
    //   name: ['dashboard', '$my', 'setting'],
    //   path: '/dashboard/my/setting',
    // },
    {
      icon: <Squares2X2Icon className="h-5" />,
      name: ['dashboard', '$my', 'app'],
      path: '/dashboard/my/app',
    },
    {
      icon: <Cog6ToothIcon className="h-5" />,
      name: ['dashboard', '$my', 'setting'],
      path: '/dashboard/my/setting',
    },
  ],
};

export function role_filter(it: T_nav_item, roles?: N_role[]): boolean {
  return roles?.includes(N_role.super) || (!!it?.roles?.length && !!intersection(roles, it.roles).length);
}
