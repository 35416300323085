import Link from 'next/link';
import React, { ComponentProps, FC, forwardRef, memo } from 'react';

export interface I_A extends ComponentProps<typeof Link> {}

const A: FC<I_A> = memo<I_A>(
  forwardRef((props: I_A, ref: any) => {
    const { ...rest } = props;

    return <Link data-testid="A" {...rest} ref={ref} />;
  }) as any,
);

export default A;
