import Avatar from '@/component/avatar/avatar';
import A_auth from '@/component/link/a_auth/a_auth';
import Tip_text from '@/component/tip/tip_text/tip_text';
import { Context_auth } from '@/context/context_auth';
import { T_token_bag } from '@/declaration/api/type/t_token_bag';
import useHrefAuth from '@/hook/auth/useHrefAuth';
import { radio } from '@/state/radio';
import AddIcon from '@mui/icons-material/Add';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { FC, memo, ReactNode, useCallback, useContext } from 'react';
import { twMerge } from 'tailwind-merge';

export interface I_Account_switcher {
  on_done?: () => void;
  class_item?: string;
  hide_add?: boolean;
  on_switch?: (bag: T_token_bag) => void;
  tip_item?: ReactNode;
  current_selectable?: boolean;
}

const Account_switcher: FC<I_Account_switcher> = memo<I_Account_switcher>((props: I_Account_switcher) => {
  const { on_done, on_switch, tip_item, hide_add, class_item, current_selectable } = props;
  const { t } = useTranslation();
  const { signer, auths } = useContext(Context_auth);
  const login = useHrefAuth('login', true);

  const handle_switch_account = useCallback(
    (bag: T_token_bag) => () => {
      radio.authorization.next(bag);
      on_switch?.(bag);
      on_done?.();
    },
    [on_done, on_switch],
  );

  return (
    <>
      {auths?.map((bag: T_token_bag) => {
        const is_current = signer?.id === bag.signer?.id;
        const { unique, id, r_user } = bag.signer || {};
        const { nickname, avatar } = r_user || {};
        const unique_name = `@${unique}`;

        return (
          <Tip_text key={bag.authorization} title={tip_item ? tip_item : (t('auth:switch_account') as string)}>
            <MenuItem disabled={is_current && !current_selectable} onClick={handle_switch_account(bag)}>
              <div className="flex gap-2 items-center">
                <div className="flex-shrink w-[25px]">
                  <Avatar avatar={avatar} />
                </div>
                <div className={twMerge('r gap-1 flex-1 items-center leading-none case_initial py-2', class_item)}>
                  <div className="r items-center">
                    {nickname && <div>{nickname}</div>}
                    <div className={nickname ? 'text-xs text_dim' : ''}>{unique ? unique_name : `id:${id}`}</div>
                  </div>
                  {is_current && (
                    <Tip_text title={t('auth:current_account')}>
                      <div>🟢</div>
                    </Tip_text>
                  )}
                </div>
              </div>
            </MenuItem>
          </Tip_text>
        );
      })}

      {!hide_add && (
        <Tip_text title={t('auth:add_account') as string}>
          <MenuItem component={A_auth} href={login} onClick={on_done}>
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('auth:add_account')}</ListItemText>
          </MenuItem>
        </Tip_text>
      )}
    </>
  );
});

export default Account_switcher;
