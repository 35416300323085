import { Tooltip } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import React, { FC, forwardRef, memo } from 'react';

export interface I_Tip extends TooltipProps {
  display?: string;
}

const Tip: FC<I_Tip> = memo<I_Tip>(
  forwardRef((props: I_Tip, ref) => {
    const { placement = 'top', children } = props;

    return (
      <Tooltip data-testid="Tip" arrow={false} placement={placement} disableInteractive ref={ref} {...props}>
        {children}
      </Tooltip>
    );
  }) as any,
);

export default Tip;
