import { build_url_cdn } from '@/utility/storage/build_url_cdn';
import React, { FC, memo } from 'react';
import { Optional } from 'utility-types';
import Icon_image, { I_Icon_image } from '../icon_image/icon_image';

export interface I_Avatar extends Optional<I_Icon_image, 'src' | 'alt'> {
  avatar?: string;
}
const placeholder = '/asset/image/avatar.svg';
const Avatar: FC<I_Avatar> = memo<I_Avatar>((props) => {
  const { src, alt, avatar } = props;
  return (
    <Icon_image
      {...props}
      src={src || (avatar && build_url_cdn(avatar)) || placeholder}
      src_placeholder={placeholder}
      alt={alt || 'Avatar'}
    />
  );
});

export default Avatar;
