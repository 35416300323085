import { Context_theme } from '@/context/context_theme';
import { N_theme } from '@/declaration/type/n_theme';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import React, { FC, HTMLAttributes, memo, ReactNode, useCallback, useContext } from 'react';

export interface I_Theme_switcher extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  children?: ((ctx: { icons: ReactNode; theme: N_theme | null }) => ReactNode) | ReactNode;
}

const Theme_switcher: FC<I_Theme_switcher> = memo<I_Theme_switcher>((props: I_Theme_switcher) => {
  const { children } = props;
  const {
    current,
    service: { current$ },
  } = useContext(Context_theme);

  const on_click = useCallback(() => {
    if (!current) {
      current$.next(N_theme.dark);
    } else if (current === N_theme.dark) {
      current$.next(N_theme.light);
    } else {
      current$.next(null);
    }
  }, [current, current$]);

  const render_icon = useCallback(
    () => (
      <>
        {!current && <BrightnessAutoIcon />}
        {current === N_theme.dark && <DarkModeIcon />}
        {current === N_theme.light && <WbSunnyIcon />}
      </>
    ),
    [current],
  );

  return (
    <div {...props} onClick={on_click}>
      {children
        ? typeof children === 'function'
          ? children({
              icons: render_icon(),
              theme: current,
            })
          : children
        : render_icon()}
    </div>
  );
});

export default Theme_switcher;
