import X_modal, { I_x_modal } from '@/component/x/x_modal/x_modal';
import { loading } from '@/utility/dynamic/loading';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { ComponentProps, FC, HTMLAttributes, memo } from 'react';
import { Optional } from 'utility-types';

const Content = dynamic(() => import('@/component/re/re_creator/re_creator'), {
  loading,
});

export interface I_X_modal_re_creator_request
  extends Optional<ComponentProps<typeof Link> & HTMLAttributes<HTMLAnchorElement>> {
  trigger: I_x_modal['trigger'];
}

const X_modal_re_creator_request: FC<I_X_modal_re_creator_request> = memo<I_X_modal_re_creator_request>(
  ({ trigger, lang, ...rest }: I_X_modal_re_creator_request) => {
    return <X_modal query="re_create_request" trigger={trigger} body={<Content request />} {...rest} />;
  },
);

export default X_modal_re_creator_request;
