import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import React, { FC, forwardRef, memo } from 'react';
import { Optional } from 'utility-types';
import Tip from '../tip/tip';

export interface I_Tip_text extends Optional<TooltipProps, 'children'> {
  text?: string;
}

const Tip_text: FC<I_Tip_text> = memo<I_Tip_text>(
  forwardRef((props: I_Tip_text, ref) => {
    const { text, children } = props;
    return (
      <Tip data-testid="Tip_text" ref={ref} {...props}>
        {children || <span className="text-muted cursor-default text-sm underline decoration-dotted">{text}</span>}
      </Tip>
    );
  }) as any,
);

export default Tip_text;
