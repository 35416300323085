import Account_switcher from '@/component/auth/account_switcher/account_switcher';
import Avatar from '@/component/avatar/avatar';
import Btn from '@/component/button/btn/btn';
import Img from '@/component/image/img';
import A_auth from '@/component/link/a_auth/a_auth';
import Link_btn from '@/component/link/link_btn/link_btn';
import Theme_switcher from '@/component/theme/theme_switcher';
import X_modal_re_creator_request from '@/component/x/x_modal/re/x_modal_re_creator_request/x_modal_re_creator_request';
import { navs_dash, navs_signer, role_filter } from '@/config/nav';
import { url_login } from '@/constant/url_login';
import { Context_auth } from '@/context/context_auth';
import { x_lang_selector } from '@/declaration/type/x_lang_selector';
import useHrefAuth from '@/hook/auth/useHrefAuth';
import { useQueryUrl } from '@/hook/router/useQueryUrl';
import { useLogo } from '@/hook/ui/useLogo';
import { useLogoText } from '@/hook/ui/useLogoText';
import { logout } from '@/state/auth';
import { loading } from '@/utility/dynamic/loading';
import { t_key_nav } from '@/utility/i18n/t_key_nav';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginIcon from '@mui/icons-material/Login';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import TranslateIcon from '@mui/icons-material/Translate';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  CSSProperties,
  FC,
  forwardRef,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

export interface I_Nav_main {
  ctn?: boolean;
  className?: string;
  height?: CSSProperties['height'];
  fixed?: boolean;
}

const menu_anchor: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const menu_trans: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const menu_paper = {
  style: {
    maxHeight: 500,
    width: '200px',
  },
};

const slot_props = { paper: menu_paper };

const Button_nf = dynamic(() => import('@/component/button/button_nf/button_nf'), {
  loading,
});
const Button_im = dynamic(() => import('@/component/button/button_im/button_im'), {
  loading,
});

const Nav_main: FC<I_Nav_main> = memo<I_Nav_main>(
  forwardRef((props: I_Nav_main, ref: any) => {
    const { ctn, className, height = '50px', fixed } = props;
    const { t } = useTranslation();
    const style = useMemo(() => ({ height }), [height]);
    const logo = useLogo();
    const logo_text = useLogoText();
    const { signer } = useContext(Context_auth);
    const { push } = useRouter();
    const q = useQueryUrl();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const to_lang_selector = useMemo(() => q({ [x_lang_selector]: '1' }), [q]);
    const logged_in = useMemo(() => !!signer?.id, [signer?.id]);
    const x_login = useHrefAuth('login', true);
    const x_signup = useHrefAuth('signup', true);

    const handle_click = useCallback((event: any): void => {
      setAnchorEl(event.currentTarget);
    }, []);

    const handle_close = useCallback((): void => {
      setAnchorEl(null);
    }, []);

    const handle_logout = useCallback(() => {
      logout();
      void push(url_login);
    }, [push]);

    const render_theme_switcher = useCallback(
      () => (
        <Theme_switcher>
          {({ icons, theme }) => (
            <MenuItem>
              <ListItemIcon>{icons}</ListItemIcon>
              <ListItemText>
                <Typography className="hover:overflow-visible" noWrap>
                  {t(`theme:${theme ?? 'auto'}`)}
                </Typography>
              </ListItemText>
            </MenuItem>
          )}
        </Theme_switcher>
      ),
      [t],
    );

    const render_menu_dash = useCallback(() => {
      const list = navs_dash.children?.filter((it) => role_filter(it, signer?.r_user?.roles_complete));
      if (!list?.length) return null;
      return (
        <>
          <MenuItem component={Link} href="/dashboard" onClick={handle_close} title={t('router:dashboard') as string}>
            <ListItemIcon>
              <DashboardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography className="hover:overflow-visible" noWrap>
                {t('router:dashboard')}
              </Typography>
            </ListItemText>
          </MenuItem>
          {list?.map((group, i) => (
            <div key={i}>
              <div>
                <Divider className="my-2" textAlign="left">
                  <div className="text-sm opacity-70">{t(group.name)}</div>
                </Divider>
                {group.children?.map((it) => {
                  return (
                    <MenuItem
                      key={it.path as string}
                      component={Link}
                      href={it.path as string}
                      onClick={handle_close}
                      title={t(it.name) as string}
                    >
                      <Typography className="hover:overflow-visible" noWrap>
                        {t(t_key_nav(it.name))}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </div>
            </div>
          ))}
          <Divider className="my-2" textAlign="left" />
        </>
      );
    }, [handle_close, signer?.r_user?.roles_complete, t]);

    const render_menu_signer = useCallback(() => {
      const list = navs_signer.children;
      if (!list?.length) return null;
      return (
        <>
          {list?.map((it) => (
            <MenuItem
              key={it.path as string}
              component={Link}
              href={it.path as string}
              onClick={handle_close}
              title={t(it.name) as string as string}
            >
              <ListItemIcon>{it.icon}</ListItemIcon>
              <ListItemText>
                <Typography className="hover:overflow-visible" noWrap>
                  {t(t_key_nav(it.name))}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
          <Divider className="my-2" textAlign="left" />
        </>
      );
    }, [handle_close, t]);

    const render_menu_language_selector = useCallback(
      () => (
        <MenuItem
          component={Link}
          href={to_lang_selector}
          onClick={handle_close}
          title={t('router:dashboard') as string}
        >
          <ListItemIcon>
            <TranslateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography className="hover:overflow-visible" noWrap>
              {t('common:select_language')}
            </Typography>
          </ListItemText>
        </MenuItem>
      ),
      [handle_close, t, to_lang_selector],
    );

    const render_menu_tool = useCallback(() => {
      return (
        <>
          {/*{render_menu_language_selector()}*/}
          {render_theme_switcher()}
          <Divider className="my-2" textAlign="left" />
          <MenuItem onClick={handle_logout} title={t('common:logout') as string}>
            <ListItemIcon>
              <PowerSettingsNewIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography className="hover:overflow-visible" noWrap>
                {t('common:logout')}
              </Typography>
            </ListItemText>
          </MenuItem>
        </>
      );
    }, [handle_logout, render_theme_switcher, t]);

    const render_menu_account = useCallback(() => {
      return (
        <>
          <Account_switcher on_done={handle_close} />
          <Divider className="my-2" textAlign="left" />
        </>
      );
    }, [handle_close]);

    const render_menu_auth = useCallback((): ReactNode => {
      if (logged_in) {
        return (
          <div>
            {render_menu_account()}
            {render_menu_dash()}
            {render_menu_signer()}
            {render_menu_tool()}
          </div>
        );
      } else {
        return (
          <div>
            <MenuItem component={A_auth} href={x_login} onClick={handle_close}>
              <ListItemIcon>
                <LoginIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="hover:overflow-visible" noWrap>
                  {t('common:login')}
                </Typography>
              </ListItemText>
            </MenuItem>
            <MenuItem component={A_auth} href={x_signup} onClick={handle_close}>
              <ListItemIcon>
                <AddCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="hover:overflow-visible" noWrap>
                  {t('common:signup')}
                </Typography>
              </ListItemText>
            </MenuItem>
            {/*{render_menu_language_selector()}*/}
            {render_theme_switcher()}
          </div>
        );
      }
    }, [
      logged_in,
      render_menu_account,
      render_menu_dash,
      render_menu_signer,
      render_menu_tool,
      x_login,
      handle_close,
      t,
      x_signup,
      render_menu_language_selector,
      render_theme_switcher,
    ]);

    const render_auth_navs = useCallback((): ReactNode => {
      if (logged_in) {
        return (
          <>
            <Link className="self-center" href="/dashboard/overview">
              {t('router:dashboard')}
            </Link>
          </>
        );
      } else {
        return (
          <>
            <A_auth className="self-center" href={x_login}>
              {t('common:login')}
            </A_auth>
            <A_auth className="self-center" href={x_signup}>
              {t('common:signup')}
            </A_auth>
          </>
        );
      }
    }, [logged_in, t, x_login, x_signup]);

    return (
      <div
        ref={ref}
        className={twMerge(`${fixed ? 'fixed left-0 right-0 top-0 z-10 bg-background' : ''}`, className)}
        data-testid="Nav_main"
      >
        <div className={ctn ? 'ctn' : ''}>
          <div style={style} className="r items-center gap-2">
            <Link href="/" className="flex flex-row items-center gap-2 self-center no-underline" title="Logo">
              <span className="w-6">
                <Img src={logo} alt="Logo" />
              </span>
              <span className="w-[70px] hidden sm:block align-middle text-lg font-black">
                <Img src={logo_text} alt="Logo" />
              </span>
              {/*<span className="hidden sm:block align-middle text-lg font-black">{env.brand ?? 'Giao'}</span>*/}
            </Link>
            <div className="content_export_hidden flex flex-1 flex-row items-center justify-end gap-2 capitalize">
              <div className="hidden items-center justify-end gap-2 capitalize md:flex">
                {render_auth_navs()}
                {/*<Link className="self-center" href="/about" title={t('router:about') as string}>*/}
                {/*  {t('router:about')}*/}
                {/*</Link>*/}
                {/*<Link className="self-center" href="/contact" title={t('router:contact') as string}>*/}
                {/*  {t('router:contact')}*/}
                {/*</Link>*/}
              </div>

              {signer ? (
                <>
                  <Link_btn href={`/lease/new`} size="sm">
                    出租/转租
                  </Link_btn>
                  <X_modal_re_creator_request trigger={<Btn size="sm">求租</Btn>} />
                </>
              ) : (
                <>
                  <A_auth redirect="/?x_re_create=1" href={q({ x_login: '1' })}>
                    <Btn size="sm">出租/转租</Btn>
                  </A_auth>
                  <A_auth redirect="/?x_re_create=1" href={q({ x_login: '1' })}>
                    <Btn size="sm">求租</Btn>
                  </A_auth>
                </>
              )}

              {logged_in && (
                <>
                  {/*<Button_im />*/}
                  <Button_nf />
                </>
              )}

              <IconButton sx={{ borderRadius: '0.125rem' }} size="small" onClick={handle_click}>
                <Avatar avatar={signer?.r_user?.avatar} />
              </IconButton>
              <Menu
                elevation={3}
                className="capitalize"
                anchorEl={anchorEl}
                open={open}
                onClose={handle_close}
                anchorOrigin={menu_anchor}
                transformOrigin={menu_trans}
                slotProps={slot_props}
              >
                {/*<div className="md:hidden">*/}
                {/*  <MenuItem component={Link} href="/about" onClick={handle_close} title={t('router:about') as string}>*/}
                {/*    <Typography className="hover:overflow-visible" noWrap>*/}
                {/*      {t('router:about')}*/}
                {/*    </Typography>*/}
                {/*  </MenuItem>*/}
                {/*  <MenuItem*/}
                {/*    component={Link}*/}
                {/*    href="/contact"*/}
                {/*    onClick={handle_close}*/}
                {/*    title={t('router:contact') as string}*/}
                {/*  >*/}
                {/*    <Typography className="hover:overflow-visible" noWrap>*/}
                {/*      {t('router:contact')}*/}
                {/*    </Typography>*/}
                {/*  </MenuItem>*/}
                {/*  <Divider />*/}
                {/*</div>*/}
                {render_menu_auth()}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    );
  }) as any,
);

export default Nav_main;
