import React, { FC, ImgHTMLAttributes, memo, useCallback, useEffect, useState } from 'react';

export interface I_Image extends ImgHTMLAttributes<HTMLImageElement> {
  max_retry?: number;
  src_placeholder?: string;
}

const Img: FC<I_Image> = memo<I_Image>(({ src, src_placeholder, max_retry = 3, ...rest }: I_Image) => {
  const [retry_count, set_retry_count] = useState<number>(0);
  const [suffix, set_suffix] = useState<string>('');
  const [_src, set__src] = useState(src);

  const retry = useCallback(() => {
    if (src?.startsWith('data:')) {
      return;
    }
    setTimeout(() => {
      try {
        const url = new URL(src as string);
        set_suffix((url.search ? '&' : '?') + Date.now());
        set_retry_count(retry_count + 1);
      } catch (e: any) {}
    }, 1000);
  }, [retry_count, src]);

  const handle_error = useCallback(() => {
    if (retry_count < max_retry) {
      retry();
    } else {
      if (src_placeholder) {
        set_suffix('');
        set__src(src_placeholder);
      }
    }
  }, [max_retry, retry, retry_count, src_placeholder]);

  useEffect(() => {
    set__src(src);
  }, [src]);

  return <img onError={handle_error} alt="Image" data-testid="Img" {...rest} src={_src + suffix} />;
});

export default Img;
