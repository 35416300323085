import Btn from '@/component/button/btn/btn';
import A from '@/component/link/a/a';
import Link from 'next/link';
import React, { ComponentProps, FC, forwardRef, memo } from 'react';
import { twMerge } from 'tailwind-merge';

type I_Link = ComponentProps<typeof Link>;

export interface I_Link_btn extends ComponentProps<typeof Btn> {
  href: I_Link['href'];
  target?: I_Link['target'];
  rel?: I_Link['rel'];
  download?: I_Link['download'];
  shallow?: I_Link['shallow'];
  scroll?: I_Link['scroll'];
  i_link?: Partial<ComponentProps<typeof Link>>;
}

const Link_btn: FC<I_Link_btn> = memo<I_Link_btn>(
  forwardRef((props: I_Link_btn, ref: any) => {
    const { i_link, href, className, shallow, scroll, target, rel, download, ...rest } = props;

    return (
      <A
        className={twMerge('inline-flex text-inherit no-underline')}
        data-testid="Link_btn"
        {...i_link}
        href={href}
        target={target}
        rel={rel}
        download={download}
        shallow={shallow}
        scroll={scroll}
        passHref
      >
        <Btn {...rest} className={className} ref={ref} />
      </A>
    );
  }) as any,
);

export default Link_btn;
