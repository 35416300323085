import Icon_loading from '@/component/icon/icon_loading/icon_loading';
import Modal_general from '@/component/modal/modal_general/modal_general';
import useQueryTo from '@/hook/router/useQueryTo';
import { useQueryUrl } from '@/hook/router/useQueryUrl';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import React, {
  ComponentProps,
  FC,
  HTMLAttributes,
  memo,
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { usePrevious } from 'react-use';
import { Optional } from 'utility-types';

export interface I_x_modal extends Optional<ComponentProps<typeof Link> & HTMLAttributes<HTMLAnchorElement>> {
  /**
   * query key
   */
  query: string;

  params?: Record<string, string | number>;

  /**
   * Link trigger content
   */
  trigger?: ReactNode;

  /**
   * Modal content
   */
  body: ReactNode;

  close?: number;
}

const X_modal: FC<I_x_modal> = memo<I_x_modal>((props: I_x_modal) => {
  const { query, params, close, body, trigger } = props;
  const close_prev = usePrevious(close);
  const _query = useMemo(() => `x_${query}`, [query]); // prefix x stands for local route query string
  const _params = useMemo(() => {
    let r: any = {};
    if (!params) return r;
    for (const key in params) {
      r[`${_query}_${key}`] = params[key];
    }
    return r;
  }, [_query, params]);
  const q = useSearchParams();
  const { subtract } = useQueryTo();
  const on_close = useCallback(() => {
    subtract([_query, ...Object.keys(_params)]);
  }, [_params, _query, subtract]);
  const qu = useQueryUrl();
  const href = useMemo(() => qu({ [_query]: '1', ..._params }), [qu, _query, _params]);

  useEffect(() => {
    if (close && close !== close_prev) {
      on_close();
    }
  }, [close, close_prev, on_close]);

  return (
    <>
      <Link data-testid="X_modal" href={href} shallow scroll={false} {...props}>
        {trigger ?? 'Open Modal'}
      </Link>
      <Modal_general open={!!q?.get(_query)} onClose={on_close}>
        <Suspense fallback={<Icon_loading />}>{body}</Suspense>
      </Modal_general>
    </>
  );
});

export default X_modal;
