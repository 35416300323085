import { config_auth } from '@/config/auth';
import { N_lkey } from '@/declaration/type/n_lkey';
import lget from '@/utility/storage/lget';
import lset from '@/utility/storage/lset';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

export interface O_use_sign_redirect {
  set: (href: string) => void;
  set_from_url: () => void;
  clear: () => void;
  run: () => void;
}

export interface I_use_sign_redirect {
  auto_set_from_url?: boolean;
}

export default function useSignRedirect(opt?: I_use_sign_redirect): O_use_sign_redirect {
  const { push } = useRouter();
  const query = useSearchParams();

  const clear = useCallback(() => {
    lset([N_lkey.sign_redirect], null);
  }, []);

  const set = useCallback((href: string) => {
    lset([N_lkey.sign_redirect], href);
  }, []);

  const set_from_url = useCallback(() => {
    const href = query.get(N_lkey.sign_redirect);
    if (!href) {
      return;
    }
    set(href);
  }, [query, set]);

  const run = useCallback(() => {
    const href = lget([N_lkey.sign_redirect]) ?? config_auth.login_redirect;
    clear();
    void push(href);
  }, [clear, push]);

  useEffect(() => {
    if (opt?.auto_set_from_url) {
      set_from_url();
    }
  }, [opt?.auto_set_from_url, set_from_url]);

  return { set, set_from_url, clear, run };
}
