import A, { I_A } from '@/component/link/a/a';
import useSignRedirect from '@/hook/auth/useSignRedirect';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import React, { FC, forwardRef, memo, useCallback } from 'react';

export interface I_A_auth extends I_A {
  /**
   * Redirect path after login
   */
  redirect?: string;
}

const A_auth: FC<I_A_auth> = memo<I_A_auth>(
  forwardRef(({ redirect, scroll, ...rest }: I_A_auth, ref: any) => {
    scroll = scroll ?? false;
    const { set } = useSignRedirect();
    const { asPath } = useRouter();
    const query = useSearchParams();

    const on_click = useCallback(() => {
      if (redirect) {
        set(redirect);
        return;
      }
      if (asPath.startsWith('/auth/') || asPath.startsWith('/passport/')) {
        return;
      }
      const qs = query.toString();
      set(asPath + (qs ? `?${qs}` : ''));
    }, [asPath, query, redirect, set]);

    return <A onClick={on_click} ref={ref} scroll={scroll} {...rest} />;
  }) as any,
);

export default A_auth;
